// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to add something do it here
@import '~react-redux-toastr/src/styles/index';

// React dates styles
@import '~react-dates/lib/css/_datepicker.css';
@import '~reactjs-popup/dist/index.css';

@import "custom";

.verifications--picture {
    max-height: 200px;
}

.date-picker {
    flex: 1 1 auto;

    .SingleDatePicker .SingleDatePickerInput .DateInput_input {
        @extend .form-control;
    }

    &.is-invalid {
        .SingleDatePicker .SingleDatePickerInput .DateInput_input {
            @extend .form-control.is-invalid;
        }
    }
}

html:not([dir="rtl"]) {
    .input-group > .date-picker:not(:last-child) .DateInput_input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.blink {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: .1;
    }
}

.rotate {
    animation: rotator 1s cubic-bezier(0.55, 0, 0.45, 1) infinite;
}

@keyframes rotator {
    100% {
        transform: rotate(360deg);
    }
}
