.text-underline {
    text-decoration: underline;
}

.half-visible {
    opacity: 0.5;
}

.min-vh-25 {
    min-height: 25vh !important;
}

.text-break-all {
    word-break: break-all !important;
    overflow-wrap: anywhere !important;
}

.cursor-pointer {
    cursor: pointer;
}
